@import "~bootstrap/scss/bootstrap";

$primary_t1: #0e2942;
$primary_t2: #000;
$primary_t3: #fff;
$primary_t4: #f0b93b;
$primary_t5: #081624;
$primary_t6: #ffc848;
$primary_t7: #081624;
$primary_t8: #14304c;
$primary_t9: #6c757d;
$primary_t10: linear-gradient(0deg, rgba(14, 38, 63, 1), rgba(17, 57, 91, 1));
$primary_t11: #0c2030;

body {
  background-color: $primary_t11 !important;
  font-family: Lato, sans-serif;
  font-weight: 400;
  color: $primary_t2;
  font-size: 14px;
  letter-spacing: 0.03em;
  overflow: hidden !important;
}

#MainLayout {
  height: 100%;
}

.header-block {
  padding-top: 160px !important;
}

.main {
  height: 100%;
  overflow: hidden !important;
}

.main .inner-container {
  padding-top: 40px;
  position: relative;
}

.main .inner-container .title {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.row-offcanvas,
body,
html {
  height: 100%;
}

.sidebar .navbar-brand {
  display: block;
  margin-left: 1rem;
  margin-bottom: 30px;
}

.sidebar {
  width: inherit;
  min-width: 100px;
  max-width: 100px;
  float: left;
  height: 100%;
  overflow-y: hidden;
  z-index: 100;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.prepend {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}

.append {
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.navy-fields .form-control {
  background-color: $primary_t7 !important;
  border-color: $primary_t7 !important;
}

#__BVID__25 {
  width: 19rem;
}

// .input-group-append {
//   width: 100px !important;
// }

.sidebar .nav:not(.bottom) {
  background-color: $primary_t5;
  border-top-right-radius: 20px;
  height: calc(100vh - 110px);
  padding-top: 15px;
}

.sidebar .nav .nav-item {
  margin-bottom: 15px;
}

.sidebar .nav .nav-item .nav-link {
  color: $primary_t3;
  position: relative;
  padding: 0;
  text-align: center;
}

.nav-link {
  display: block;
}

.rounded-br-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-tr-0 {
  border-top-right-radius: 0 !important;
}

.sidebar .nav .nav-item .nav-link.active > div {
  background-color: $primary_t6;
  color: $primary_t2;
}

.sidebar .nav .nav-item .nav-link > div {
  border-radius: 10px;
  height: 65px;
  width: 65px;
  line-height: 65px;
}

.sidebar .nav.bottom .nav-link > div {
  height: auto;
  min-height: 65px;
  line-height: 1;
}

.sidebar,
.sidebar-sticky {
  position: relative;
  overflow-x: hidden;
}

a {
  color: $primary_t4;
}

.fa-2x {
  font-size: 2em;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-w-20 {
  width: 1.2em;
}

.sidebar .nav.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebar .nav .nav-item .nav-link .text {
  font-size: 12px;
}

.bg-dark-navy {
  background-color: $primary_t7 !important;
}

.toggle-login {
  display: none !important;
}

.main {
  height: 100%;
  overflow: auto;
}

.main .inner-container .inner-row,
.main .inner-container .inner-row {
  height: calc(100vh - 135px);
}

.main .accordion,
.main .custom {
  border-radius: 20px;
  overflow: hidden;
}

.main .card.custom .card-header {
  border: none;
  padding-top: 10px;
  padding-bottom: 6px;
}

.main .accordion .card-header .btn,
.main .custom .card-footer,
.main .custom .card-header {
  background-color: $primary_t8;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.color-white {
  color: $primary_t3 !important;
}

.color-black {
  color: $primary_t2 !important;
}

.color-yellow {
  color: $primary_t4 !important;
}

.bg-yellow {
  background-color: $primary_t4 !important;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn .btn-secondary .btn-block .collapsed {
  overflow-anchor: none;
}

.main .accordion .card-header .btn {
  text-align: left;
  font-weight: 400;
  padding: 10px 15px;
  border-radius: 0;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.btn-group .btn,
.main .accordion .card-header .btn,
.outline-removed {
  outline: none;
  border: none !important;
  box-shadow: none !important;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn .btn-secondary .btn-block .collapsed {
  overflow-anchor: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: $primary_t3;
  background-color: $primary_t9;
  border-color: $primary_t9;
}

.fa-user-lock::before {
  content: "\f502";
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}

.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900 !important;
  color: #15385b;
  letter-spacing: 0.05em;
}

.left-div {
  max-width: 100% !important;
  min-width: 30% !important;
  height: calc(100vh - 135px) !important;
  overflow: auto;
}

.right-div {
  overflow: auto;
  height: calc(100vh - 135px) !important;
}

.popout-icon {
  color: #fff;
}

.image-placeholder {
  background-image: url("../img/xray.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-label {
  color: #fff !important;
}

.rc-slider-rail {
  background-color: $primary_t4;
}

.image-list-parent > div {
  overflow-y: auto !important;
}

.image-list-parent h5 {
  white-space: pre-wrap !important;
}

